import i18n from "i18next";

export type OpenGraph = {
  image: string;
  type?: string;
  url: string;
  description: string;
  siteName: string;
  title: string;
};
export type Twitter = {
  image: string;
  site: string;
  description: string;
};
export type Meta = {
  title: string;
  description: string;
  keywords: string;
  relCanonical: string;
  og: OpenGraph;
  twitter: Twitter;
  robots?: string;
};
export const defaultMetaTitle = i18n.t("page.head.meta.title");
export const defaultMetaDescription = i18n.t("page.head.meta.description");
export const defaultMetaRelCanonical = i18n.t("page.head.meta.relCanonical");
export const defaultKeywords = i18n.t("page.head.meta.keywords");
export const defaultOgImage = i18n.t("page.head.meta.og.image");
export const defaultOgSiteName = i18n.t("page.head.meta.og.siteName");
export const defaultTwitterImage = i18n.t("page.head.meta.twitter.image");
export const defaultTwitterSite = i18n.t("page.head.meta.twitter.site");
export const defaultMeta = (): Meta => {
  return {
    title: defaultMetaTitle,
    description: defaultMetaDescription,
    keywords: defaultKeywords,
    relCanonical: defaultMetaRelCanonical,
    og: {
      image: defaultOgImage,
      title: defaultMetaTitle,
      url: defaultMetaRelCanonical,
      description: defaultMetaDescription,
      siteName: defaultOgSiteName,
    },
    twitter: {
      image: defaultTwitterImage,
      site: defaultTwitterSite,
      description: defaultMetaDescription,
    },
  };
};
