import React, { useEffect } from "react";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { RISKIFIED_CART_TOKEN_KEY } from "storefront/GrailedAPI/request/Headers";
import { safelyGetItem, safelySetItem } from "storefront/lib/Storage";
import type { Meta } from "./Head/Meta";

const branchScriptCode = (branchKeyLive: string) => `
  (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId".split(" "), 0);
  branch.init("${branchKeyLive}", function(err, data) {
    // callback to handle err or data
  });
`;

const googleTagManagerUrl = (googleId: string) =>
  `https://www.googletagmanager.com/gtag/js?id=${googleId}`;

const googleTagManagerScriptCode = (googleId: string) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
  });

  gtag('js', new Date());
  gtag('config', '${googleId}', {'allow_enhanced_conversions':true});
`;

const facebookLoginScriptCode = (facebookAppId: string) => `
  window.fbAsyncInit = function() {
    FB.init({
      appId            : "${facebookAppId}",
      autoLogAppEvents : true,
      xfbml            : true,
      status           : true,
      version          : 'v2.12'
    });
  };
`;

const pinterestTagCode = `
!function(e){if(!window.pintrk){window.pintrk = function () {
window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
  n=window.pintrk;n.queue=[],n.version="3.0";var
  t=document.createElement("script");t.async=!0,t.src=e;var
  r=document.getElementsByTagName("script")[0];
  r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
`;

const tikTokTagCode = `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
}(window, document, 'ttq');
`;

const snapchatCode = `
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
        a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
        r.src=n;var u=t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r,u);})(window,document,
        'https://sc-static.net/scevent.min.js');
`;

const oneTrustCode = `function OptanonWrapper(){
    // Get initial OnetrustActiveGroups ids
    if(typeof OptanonWrapperCount == "undefined"){
      otGetInitialGrps();
    }
    
    //Delete cookies
    otDeleteCookie(otIniGrps);
 
    // Assign OnetrustActiveGroups to custom variable
    function otGetInitialGrps(){
        OptanonWrapperCount = '';
        otIniGrps =  OnetrustActiveGroups;
    }
 
    function otDeleteCookie(iniOptGrpId)
    {
        var otDomainGrps = JSON.parse(JSON.stringify(Optanon.GetDomainData().Groups));
        var otDeletedGrpIds = otGetInactiveId(iniOptGrpId, OnetrustActiveGroups);
        if(otDeletedGrpIds.length != 0 && otDomainGrps.length !=0){
            for(var i=0; i < otDomainGrps.length; i++){
                //Check if CustomGroupId matches
               if(otDomainGrps[i]['CustomGroupId'] != '' && otDeletedGrpIds.includes(otDomainGrps[i]['CustomGroupId'])){
                    for(var j=0; j < otDomainGrps[i]['Cookies'].length; j++){
                        //Delete cookie
                        eraseCookie(otDomainGrps[i]['Cookies'][j]['Name']);
                    }
                }
 
                //Check if Hostid matches
                if(otDomainGrps[i]['Hosts'].length != 0){
                    for(var j=0; j < otDomainGrps[i]['Hosts'].length; j++){
                        //Check if HostId presents in the deleted list and cookie array is not blank
                        if(otDeletedGrpIds.includes(otDomainGrps[i]['Hosts'][j]['HostId']) && otDomainGrps[i]['Hosts'][j]['Cookies'].length !=0){
                            for(var k=0; k < otDomainGrps[i]['Hosts'][j]['Cookies'].length; k++){
                                //Delete cookie
                                eraseCookie(otDomainGrps[i]['Hosts'][j]['Cookies'][k]['Name']);
                            }
                        }
                    }
                }
            }
        }
        otGetInitialGrps(); //Reassign new group ids
    }
 
    //Get inactive ids
    function otGetInactiveId(customIniId, otActiveGrp){
        //Initial OnetrustActiveGroups
        customIniId = customIniId.split(",");
        customIniId = customIniId.filter(Boolean);
 
        //After action OnetrustActiveGroups
        otActiveGrp = otActiveGrp.split(",");
        otActiveGrp = otActiveGrp.filter(Boolean);
 
        var result=[];
        for (var i=0; i < customIniId.length; i++){
            if ( otActiveGrp.indexOf(customIniId[i]) <= -1 ){
                result.push(customIniId[i]);
            }
        }
        return result;
    }
 
    //Delete cookie
    function eraseCookie(name) {
        //Delete root path cookies
        domainName = window.location.hostname.replace(/^www(.)/, '$1');
        document.cookie = name+'=; Max-Age=-99999999; Path=/;Domain='+ domainName;
        document.cookie = name+'=; Max-Age=-99999999; Path=/;';
 
        //Delete LSO incase LSO being used, cna be commented out.
        localStorage.removeItem(name);
 
        //Check for the current path of the page
        pathArray = window.location.pathname.split('/');
        //Loop through path hierarchy and delete potential cookies at each path.
        for (var i=0; i < pathArray.length; i++){
            if (pathArray[i]){
                //Build the path string from the Path Array e.g /site/login
                var currentPath = pathArray.slice(0,i+1).join('/');
                document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + ';Domain='+ domainName;
                document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + ';';
                //Maybe path has a trailing slash!
                document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + '/;Domain='+ domainName;
                document.cookie = name+'=; Max-Age=-99999999; Path=' + currentPath + '/;';
            }
        }
    }
}`;

const getSessionId = () => {
  let sessionId = safelyGetItem(global.window.localStorage)(
    RISKIFIED_CART_TOKEN_KEY,
  );

  if (!sessionId) {
    sessionId = uuidv4();
    safelySetItem(global.window.localStorage)(
      RISKIFIED_CART_TOKEN_KEY,
      sessionId,
    );
  }

  return sessionId;
};

const getRiskifiedSrc = (storeDomain: string, sessionId: string): string =>
  `${
    global.document.location.protocol === "https:" ? "https://" : "http://"
  }beacon.riskified.com?shop=${storeDomain}&sid=${sessionId}`;

type Props = {
  doNotTrack: boolean;
  meta: Meta;
};

const DocumentHead = ({ doNotTrack, meta }: Props) => {
  const verificationId = process.env.GOOGLE_MERCHANT_CENTER_VERIFICATION_ID;
  const branchKeyLive = process.env.BRANCH_KEY_LIVE;
  const facebookAppId = process.env.FB_APP_ID;
  const riskifiedShopDomain = process.env.RISKIFIED_SHOP_DOMAIN;
  const nextPublicOneTrustDomainScript =
    process.env.NEXT_PUBLIC_ONE_TRUST_DOMAIN_SCRIPT;

  // TODO: move to config var?
  const googleId = "AW-800578442";
  const { t } = useTranslation("common");
  const { og, twitter } = meta;

  useEffect(() => {
    const riskifiedSrc: string | null = riskifiedShopDomain
      ? getRiskifiedSrc(riskifiedShopDomain, getSessionId())
      : null;

    if (riskifiedSrc) {
      let script: HTMLScriptElement | null = global.document.querySelector(
        `script[src='${riskifiedSrc}']`,
      );

      if (!script) {
        script = global.document.createElement("script");
        script.src = riskifiedSrc;
        script.async = true;
        global.document.body.appendChild(script);
      }
    }
  }, [riskifiedShopDomain]);

  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <link rel="canonical" href={meta.relCanonical} />
      {meta.robots && <meta name="robots" content={meta.robots} />}
      {/* Open Graph */}
      <meta property="og:image" content={og.image} />
      <meta property="og:url" content={og.url} />
      <meta property="og:description" content={og.description} />
      <meta property="og:site_name" content={og.siteName} />
      <meta property="og:title" content={og.title} />
      {og.type && <meta property="og:type" content={og.type} />}
      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={twitter.image} />
      <meta name="twitter:site" content={twitter.site} />
      <meta name="twitter:description" content={twitter.description} />
      <meta name="twitter:title" content="" />
      <meta
        id="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2, viewport-fit=cover"
        key="viewport"
      />
      <meta name="referrer" content="strict-origin-when-cross-origin" />
      {/* Google Site Verification */}
      {verificationId && (
        <meta
          name="google-site-verification"
          content={verificationId}
          key="google-site-verification"
        />
      )}
      {/* favicon */}
      <link
        rel="icon"
        type="image/x-icon"
        href={t("page.head.meta.favicon.48")}
        sizes="48x48"
        key="favicon-48x48"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href={t("page.head.meta.favicon.32")}
        sizes="32x32"
        key="favicon-32x32"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href={t("page.head.meta.favicon.16")}
        sizes="16x16"
        key="favicon-16x16"
      />
      {/** One Trust -- MUST LOAD FIRST */}
      {nextPublicOneTrustDomainScript ? (
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-language="en"
          type="text/javascript"
          data-domain-script={nextPublicOneTrustDomainScript}
        />
      ) : null}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: oneTrustCode,
        }}
      />
      {/* Contentful Preload */}
      <link
        href="https://cdn.contentful.com"
        rel="preconnect"
        crossOrigin="anonymous"
        key="contentful-preconnect"
      />
      {/* Segment Preload */}
      <link
        href="https://api.segment.io"
        rel="preconnect"
        crossOrigin="anonymous"
        key="segment-preconnect"
      />
      {/* Branch Script */}
      {branchKeyLive ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: branchScriptCode(branchKeyLive),
          }}
          key="branch-inline-script"
        />
      ) : null}
      {/* Facebook Login */}
      <script async defer src="https://connect.facebook.net/en_US/sdk.js" />
      {facebookAppId ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: facebookLoginScriptCode(facebookAppId),
          }}
        />
      ) : null}
      {/* Google Tag Manager Script */}
      {/* NOTE: we're using the process.browser guard here because using `async` in the script tag here cause a "double render" issue: https://github.com/vercel/next.js/issues/9070 - it appears that later versions of NextJS resolve this problem through the new library `next/script` */}
      {!doNotTrack && process.browser && googleId ? (
        <>
          <script
            type="text/javascript"
            async
            src={googleTagManagerUrl(googleId)}
            key="google-tag-manger-script"
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: googleTagManagerScriptCode(googleId),
            }}
            key="google-inline-script"
          />
        </>
      ) : null}
      {/* Pinterest tag */}
      {!doNotTrack ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: pinterestTagCode,
          }}
          key="pinterest-tag-script"
        />
      ) : null}
      {/* TikTok tag */}
      {!doNotTrack ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: tikTokTagCode,
          }}
          key="tiktok-tag-script"
        />
      ) : null}
      {/* Snapchat tag */}
      {!doNotTrack ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: snapchatCode,
          }}
          key="snapchat-tag-script"
        />
      ) : null}
    </Head>
  );
};

export default DocumentHead;
